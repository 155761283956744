<ion-content scroll="false" class="ion-padding">
    <div *ngIf="resetPasswordForm" class="login-box">
        <img #logo class="tp-logo-image ion-padding-bottom" />
        <ion-list class="ion-margin-top">
            <form [formGroup]="resetPasswordForm">
                <ion-item>
                    <ion-label position="stacked">{{ 'label.password' | translate}}</ion-label>
                    <ion-input
                        [type]="hide ? 'password' : 'text'"
                        class="txtPassword"
                        [(ngModel)]="resetPasswordData['password']"
                        (ionFocus)="onTouch()"
                        (input)="checkPasswordValidation($event.target)"
                        (keyup.enter)="submitResetPasswordForm()"
                        formControlName="password"></ion-input>
                    <ion-icon
                        slot="end"
                        (click)="hide = !hide"
                        name="{{ hide ? 'eye-off' : 'eye' }}"
                        style="font-size: 16px; cursor: pointer"></ion-icon>
                </ion-item>

                <div *ngIf="showValidationMessages" class="validation-list">
                    <div
                        [ngClass]="{
                            valid: global.isPasswordValid.minLength,
                            invalid: !global.isPasswordValid.minLength
                        }">
                        <span>{{ global.isPasswordValid.minLength ? '✔️' : '❌' }}</span> At least 8 characters long
                    </div>
                    <div
                        [ngClass]="{
                            valid: global.isPasswordValid.lowerCase,
                            invalid: !global.isPasswordValid.lowerCase
                        }">
                        <span>{{ global.isPasswordValid.lowerCase ? '✔️' : '❌' }}</span> At least one lowercase letter
                    </div>
                    <div
                        [ngClass]="{
                            valid: global.isPasswordValid.upperCase,
                            invalid: !global.isPasswordValid.upperCase
                        }">
                        <span>{{ global.isPasswordValid.upperCase ? '✔️' : '❌' }}</span> At least one uppercase letter
                    </div>
                    <div [ngClass]="{valid: global.isPasswordValid.number, invalid: !global.isPasswordValid.number}">
                        <span>{{ global.isPasswordValid.number ? '✔️' : '❌' }}</span> Contains at least one number
                    </div>
                    <div [ngClass]="{valid: global.isPasswordValid.symbol, invalid: !global.isPasswordValid.symbol}">
                        <span>{{ global.isPasswordValid.symbol ? '✔️' : '❌' }}</span> Contains at least one symbol
                        (!@#$%^&*)
                    </div>
                </div>
                <ion-item>
                    <ion-label position="stacked">{{ 'label.confirm_password' | translate}}</ion-label>
                    <ion-input
                        type="password"
                        class="txtPassword"
                        [type]="hideConfirmPassword? 'password' : 'text'"
                        [(ngModel)]="resetPasswordData['password2']"
                        (ionFocus)="onTouch()"
                        (keyup.enter)="submitResetPasswordForm()"
                        formControlName="password2"></ion-input>
                    <ion-icon
                        slot="end"
                        (click)="hideConfirmPassword = !hideConfirmPassword"
                        name="{{ hideConfirmPassword ? 'eye-off' : 'eye' }}"
                        style="font-size: 16px; cursor: pointer"></ion-icon>
                </ion-item>

                <div *ngIf="submitAttempt && (resetPasswordData['password'] !== resetPasswordData['password2'])">
                    <br />
                    <em color="danger">{{ 'errors.password_not_match' | translate}}</em>
                </div>
            </form>
        </ion-list>
        <ion-row padding>
            <ion-col>
                <ion-button
                    color="primary"
                    tappable
                    [disabled]="!resetPasswordForm.valid || resetPasswordData['password'] !== resetPasswordData['password2'] || !resetPasswordData['password'] "
                    id="resetPasswordBtn"
                    (click)="submitResetPasswordForm()"
                    expand="block"
                    >{{ 'label.reset_password' | translate}}</ion-button
                >
            </ion-col>
        </ion-row>
    </div>
</ion-content>
