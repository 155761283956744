<div class="comobo-box">
    <mat-form-field class="w-full" *ngIf="selectedItems && (selectedItems.length === 0 || multiChoice)">
        <input
            combo-box-input
            matInput
            type="text"
            (focus)="ajaxOptions && loadAjaxOptions(null); showOptions = true"
            (blur)="resetOption()"
            [(ngModel)]="filterObject.searchQuery"
            (keyup)="ajaxOptions && loadAjaxOptions(null)"
            [placeholder]="searchBarPlaceHolder"
            [required]="required === '' || required"
            [matAutocomplete]="auto"
            [disabled]="disabled" />

        <mat-error appFieldErrors customPropertyName="{{ label }}"></mat-error>

        <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngIf="searchingInProgress">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </mat-option>
            <ng-container *ngIf="listObject['list'] && listObject['list'].length === 0">
                <mat-option>{{ isUnitField ? 'Unit number not filled.' : 'No data available' }}</mat-option>
            </ng-container>
            <mat-option *ngFor="let item of listObject['list']" (click)="actionOnItem(item)">
                <span
                    *ngIf="showStatus"
                    class="status-dot"
                    [ngClass]="{'green-dot': item.isActive === 1, 'red-dot': item.isActive === 0}">
                </span>
                <ion-col *ngIf="translateTitle">
                    {{ item[listObject['title']] | translate }}
                </ion-col>
                <ion-col *ngIf="!translateTitle" [innerHTML]="item[listObject['title']]"></ion-col>
                <!-- <ion-radio slot="start " [disabled]="item.disabled " [value]="item[listObject.value] "></ion-radio> -->
            </mat-option>

            <ion-infinite-scroll class="hidden" threshold="50%" (ionInfinite)="loadAjaxOptions($event)">
                <ion-infinite-scroll-content
                    loadingText="{{ 'label.loading_more_data' | translate }} "></ion-infinite-scroll-content>
            </ion-infinite-scroll>
        </mat-autocomplete>
    </mat-form-field>
    <div *ngIf="multiChoice" class="flex w-full">
        <!--  <ion-row class="selected-option" *ngFor="let item of selectedItems" class="flex">
            <ion-col *ngIf="item[listObject['title']]">
                <div class="inline-title">{{ item[listObject['title']] }}</div>
            </ion-col>
            <ion-col class="close-col ion-text-center" (click)="removeItem(item)" *ngIf="item[listObject['title']]">
                <ion-icon color="danger" name="close-sharp"></ion-icon>
            </ion-col>
        </ion-row> -->
        <mat-chip-list class="flex">
            <ng-container *ngFor="let item of selectedItems">
                <mat-chip
                    *ngIf="item[listObject['title']]"
                    class="flex"
                    [removable]="true"
                    (removed)="removeItem(item)">
                    <span>{{ item[listObject['title']] }}</span>
                    <ion-icon matChipRemove color="danger" name="close-sharp"></ion-icon>
                </mat-chip>
            </ng-container>
        </mat-chip-list>
    </div>

    <mat-form-field *ngIf="selectedItems.length && !multiChoice" class="w-full">
        <input
            matInput
            [value]="item[listObject['title']]"
            *ngFor="let item of selectedItems"
            readonly
            [disabled]="disabled" />

        <ion-col *ngIf="!disabled" matSuffix class="close-col ion-text-center cursor-pointer" (click)="clear()">
            <ion-icon class="text-xl" color="danger" name="close-sharp"></ion-icon>
        </ion-col>
    </mat-form-field>
</div>
