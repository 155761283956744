import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {GlobalService} from 'src/app/services/global.service';
import {ModalController, NavParams} from '@ionic/angular';
import {ApiResponseService} from 'src/app/services/api-response.service';
import * as dayjs from 'dayjs';
import {canadianProvinces, genderOptions} from '../../../kyc.constant';

@Component({
    selector: 'app-add-stakeholder',
    templateUrl: './add-stakeholder.modal.html',
    styleUrls: ['./add-stakeholder.modal.scss'],
})
// tslint:disable-next-line:component-class-suffix
export class AddStakeholderModal implements OnInit {
    public accountAddress: any = {};
    public hideOtherFields = true;
    public hideNextAction = true;
    kycBasicInformationId;
    stakeholders: any = {};
    genders = genderOptions;
    kycId;
    address;
    companyType;
    selectedGender;
    stakeholderDesignations: any = [];
    stakeholderDesignationOptions = [];
    employmentSectors = [];
    stakeholderEmploymenSector;
    selectedNationality = 'CA';
    nationalitiesOptions;
    today: Date = new Date();
    public googleAddress = true;
    occupationRequired = false;
    kycBasicInformationIdFromParent;
    canadianProvinces = canadianProvinces;

    constructor(public global: GlobalService, public modalCtrl: ModalController, private navParams: NavParams, private changeDetectorRef: ChangeDetectorRef, private apiResponseService: ApiResponseService) {
        this.kycBasicInformationIdFromParent = parseInt(this.navParams.get('kycBasicInformationId'), 10);
        if (this.kycBasicInformationIdFromParent) {
            this.getStakeholdersDetails(this.kycBasicInformationIdFromParent);
        }
    }
    ngOnInit() {
        this.getEmploymentSectors();
        this.apiResponseService.getNationalities().subscribe((response) => {
            this.nationalitiesOptions = response.options;
        });
        this.apiResponseService.getKycBaseDetails().subscribe((response) => {
            this.companyType = response.companyInfo.companyTypeId;
            this.getStakeHoldersDesignation();
        });
        this.apiResponseService.getKycDetails().subscribe((response) => {
            this.kycBasicInformationId = response.info.companyInfo.kycCompanyInformationId;
            this.kycId = response.baseDetails.kycInfo.kycId;
        });
    }
    getStakeholdersDetails(kycBasicInformationId) {
        this.apiResponseService.getStakeholdersById(kycBasicInformationId).subscribe((response) => {
            this.stakeholders = response;

            this.stakeholders['dob'] = response.dateOfBirth;
            this.stakeholderDesignations = response.designations.map((d) => d.kycStakeholderDesignationTypeId);

            this.selectedGender = response.gender;
            this.stakeholderEmploymenSector = response.kycEmploymentSectorId;
            this.stakeholders['country'] = response.address.country;
            this.stakeholders['province'] = response.address.province;
            this.stakeholders['city'] = response.address.city;
            this.stakeholders['buildingNumber'] = response.address.buildingNumber;
            this.stakeholders['streetName'] = response.address.streetName;
            this.stakeholders['postalCode'] = response.address.postalCode;
            this.stakeholders['fullAddress'] = response.address.fullAddress;
            this.stakeholders['unitNumber'] = response.address.unitNumber;
            this.hideOtherFields = false;
        });
    }
    async saveStakeHolders(saveStakeHolder) {
        const saveStakeholderbody = {};
        if (this.kycBasicInformationIdFromParent) {
            saveStakeholderbody['kycBasicInformationId'] = this.kycBasicInformationIdFromParent.toString();
        }
        saveStakeholderbody['firstName'] = saveStakeHolder.value['firstName'];
        saveStakeholderbody['occupation'] = saveStakeHolder.value['occupation'];
        saveStakeholderbody['kycCompanyInformationId'] = this.kycBasicInformationId;
        saveStakeholderbody['lastName'] = saveStakeHolder.value['lastName'];
        saveStakeholderbody['dateOfBirth'] = dayjs(saveStakeHolder.value['dob']).format('YYYY-MM-DD');
        if (this.companyType === '4') {
            saveStakeholderbody['designations'] = this.stakeholderDesignationOptions.filter((option) => [1].includes(Number(option.kycStakeholderDesignationTypeId))).map((option) => option.kycStakeholderDesignationTypeId);
        } else {
            saveStakeholderbody['designations'] = this.stakeholderDesignations;
        }

        saveStakeholderbody['kycEmploymentSectorId'] = this.stakeholderEmploymenSector;
        saveStakeholderbody['nationalityAlpha2Code'] = this.selectedNationality;
        saveStakeholderbody['gender'] = this.selectedGender;
        saveStakeholderbody['phoneNumber'] = saveStakeHolder.value['phoneNumber'];
        const address = {
            country: this.stakeholders['country'],
            province: this.stakeholders['province'],
            city: this.stakeholders['city'],
            buildingNumber: this.stakeholders['buildingNumber'],
            streetName: this.stakeholders['streetName'],
            postalCode: this.stakeholders['postalCode'],
            fullAddress: this.stakeholders['fullAddress'],
            unitNumber: this.stakeholders['unitNumber'],
        };
        if (!address.fullAddress) {
            address.fullAddress = `${address.buildingNumber} ${address.streetName}, ${address.city}, ${address.province},  ${address.postalCode}, ${address.country}`.trim();
        }
        saveStakeholderbody['address'] = address;
        if (this.global.isFormValid(saveStakeHolder)) {
            this.global.showLoading();
            this.apiResponseService.submitStakeHoldersinfo(saveStakeholderbody).subscribe((response) => {
                this.global.basicAlert('', 'Information saved successfully.');
                this.global.closeModal(false);
                this.global.hideLoading();
            });
        }
    }
    isDesignationSelected(designationTypeId: string): boolean {
        return this.stakeholderDesignations.includes(designationTypeId);
    }
    showFields() {
        this.hideOtherFields = false;
        this.stakeholders['country'] = 'Canada';
    }

    getStakeHoldersDesignation() {
        this.apiResponseService.getStakeHoldersDesignation().subscribe((response) => {
            this.stakeholderDesignationOptions = response;
            switch (this.companyType) {
                case '2':
                    this.stakeholderDesignationOptions = this.stakeholderDesignationOptions.filter((option) => [1, 2, 3, 4].includes(Number(option.kycStakeholderDesignationTypeId)));
                    break;
                case '3':
                    this.stakeholderDesignationOptions = this.stakeholderDesignationOptions.filter((option) => [5, 2, 6, 7].includes(Number(option.kycStakeholderDesignationTypeId)));
                    break;
                case '4':
                    this.stakeholderDesignationOptions = this.stakeholderDesignationOptions.filter((option) => [1, 2, 4].includes(Number(option.kycStakeholderDesignationTypeId)));
                    break;
                case '5':
                    this.stakeholderDesignationOptions = this.stakeholderDesignationOptions.filter((option) => [1, 2, 4].includes(Number(option.kycStakeholderDesignationTypeId)));
                    break;
                default:
                    break;
            }
        });
    }
    getStakeholderDesignationsByCompanyType() {
        switch (this.companyType) {
            case '2':
                return this.stakeholderDesignationOptions.filter((x) => [5, 6, 7].includes(x.kycStakeholderDesignationTypeId));
            case '3':
                return this.stakeholderDesignationOptions.filter((x) => [5, 6, 7, 3].includes(x.kycStakeholderDesignationTypeId));
            case '4':
                return this.stakeholderDesignationOptions.filter((x) => [1, 3, 4].includes(x.kycStakeholderDesignationTypeId));
        }
    }

    UBOinfo() {
        let alertText = '';
        switch (this.companyType) {
            case '2':
                alertText = `
                    All persons who
                    directly or indirectly
                    own or control 25% or
                    more of the shares of
                    the corporation.
                `;
                break;
            case '3':
                alertText = `
                    All trustees and all
                    known beneficiaries
                    and settlors of the
                    trust.
                `;
                break;
            case '4':
                alertText = `
                    All persons who
                    directly or indirectly
                    own or control 25% or
                    more of the shares of
                    the co-ops.
                `;
                break;
            case '5':
                alertText = `
                    All persons who
                    directly or indirectly
                    own or control 25% or
                    more of the shares of
                    the entity.
                `;
                break;
            default:
                alertText = 'Invalid company type ID';
                break;
        }
        return alertText;
    }

    getEmploymentSectors() {
        this.apiResponseService.getEmploymentSectors().subscribe((response) => {
            this.employmentSectors = response;
        });
    }

    checkboxOnclick(event) {
        if (event.checked) {
            this.stakeholderDesignations.push(event.source.value);
        } else {
            this.stakeholderDesignations = this.stakeholderDesignations.filter((x) => x != event.source.value);
        }

        const hasSignatory = this.stakeholderDesignations.some((option) => option === '2');

        this.occupationRequired = hasSignatory;
    }
    setEmploymentSector($event) {
        this.stakeholderEmploymenSector = $event.value;
    }

    setGender($event) {
        this.selectedGender = $event.value;
    }

    setNationality($event) {
        this.selectedNationality = $event.value;
    }
    locationResults(place): void {
        //const address = this.global.processAdress(place['address_components']);
        this.stakeholders['country'] = place['country'];
        this.stakeholders['province'] = place['province'];
        this.stakeholders['city'] = place['city'];
        this.stakeholders['buildingNumber'] = place['buildingNumber'];
        this.stakeholders['streetName'] = place['streetName'];
        this.stakeholders['postalCode'] = place['postalCode'];
        this.stakeholders['fullAddress'] = place['fullAddress'];
        this.stakeholders['unitNumber'] = place['unitNumber'];
        this.hideOtherFields = false;
    }
}
