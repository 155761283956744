<!-- sign in form -->
<form [formGroup]="form" class="form">
    <!-- manage login heading -->
    <div class="form__heading-container">
        <ion-text class="form__heading">
            {{ 'label.reset_password' | translate }}
        </ion-text>
    </div>

    <div class="w-full w-100 form__form-field mb-3">
        <mat-label class="form__input-label">{{ 'label.email' | translate }} </mat-label>
        <mat-form-field>
            <input
                matInput
                formControlName="email"
                type="text"
                required
                appNoLeadingTrailingSpaces
                data-cy="app-login-form-email" />
            <mat-error appFieldErrors></mat-error>
        </mat-form-field>
    </div>

    <div class="w-full w-100 form__form-field">
        <mat-label class="form__input-label">{{ 'label.old_password' | translate }} </mat-label>
        <mat-form-field>
            <input matInput formControlName="oldPassword" [type]="hideOld ? 'password' : 'text'" required />
            <ion-icon matSuffix (click)="hideOld = !hideOld" name="{{ hideOld ? 'eye-off' : 'eye' }}"></ion-icon>
            <mat-error appFieldErrors></mat-error>
        </mat-form-field>
    </div>
    <div class="w-full w-100 form__form-field">
        <mat-label class="form__input-label">{{ 'label.new_password' | translate }} </mat-label>
        <mat-form-field>
            <input
                matInput
                formControlName="newPassword"
                [type]="hideNew ? 'password' : 'text'"
                required
                (input)="checkPasswordValidation($event.target)" />
            <ion-icon matSuffix (click)="hideNew = !hideNew" name="{{ hideNew ? 'eye-off' : 'eye' }}"></ion-icon>
            <mat-error appFieldErrors></mat-error>
        </mat-form-field>
        <div *ngIf="showValidationMessages" class="validation-list mb-5">
            <div
                [ngClass]="{
                    valid: global.isPasswordValid.minLength,
                    invalid: !global.isPasswordValid.minLength
                }">
                <span>{{ global.isPasswordValid.minLength ? '✔️' : '❌' }}</span> At least 8 characters long
            </div>
            <div
                [ngClass]="{
                    valid: global.isPasswordValid.lowerCase,
                    invalid: !global.isPasswordValid.lowerCase
                }">
                <span>{{ global.isPasswordValid.lowerCase ? '✔️' : '❌' }}</span> At least one lowercase letter
            </div>
            <div
                [ngClass]="{
                    valid: global.isPasswordValid.upperCase,
                    invalid: !global.isPasswordValid.upperCase
                }">
                <span>{{ global.isPasswordValid.upperCase ? '✔️' : '❌' }}</span> At least one uppercase letter
            </div>
            <div [ngClass]="{valid: global.isPasswordValid.number, invalid: !global.isPasswordValid.number}">
                <span>{{ global.isPasswordValid.number ? '✔️' : '❌' }}</span> Contains at least one number
            </div>
            <div [ngClass]="{valid: global.isPasswordValid.symbol, invalid: !global.isPasswordValid.symbol}">
                <span>{{ global.isPasswordValid.symbol ? '✔️' : '❌' }}</span> Contains at least one symbol (!@#$%^&*)
            </div>
        </div>
    </div>
    <div class="w-full w-100 form__form-field">
        <mat-label class="form__input-label">{{ 'label.confirm_password' | translate }} </mat-label>
        <mat-form-field>
            <input matInput [type]="hideConfirm ? 'password' : 'text'" formControlName="confirmPassword" required />
            <ion-icon
                matSuffix
                (click)="hideConfirm = !hideConfirm"
                name="{{ hideConfirm ? 'eye-off' : 'eye' }}"></ion-icon>
            <mat-error appFieldErrors></mat-error>
        </mat-form-field>
    </div>

    <ion-button
        type="submit"
        class="w-full btn__primary form__btn w-100"
        tappable
        [disabled]="form.invalid"
        id="loginBtn"
        expand="block"
        (click)="resetPassword()"
        >{{ 'label.reset_password' | translate }}</ion-button
    >
</form>
