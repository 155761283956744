import {Component, Input, SimpleChanges} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';

import {GlobalService} from 'src/app/services/global.service';
import {ApiResponseService} from 'src/app/services/api-response.service';
import {mustMatch} from 'src/app/utils/custom-validators';

@Component({
    selector: 'app-reset-random-password',
    templateUrl: './reset-random-password.component.html',
    styleUrls: ['../forms-shared.scss'],
})
export class ResetRandomPasswordComponent {
    /** user login form group */
    form = this._initForm();
    hideOld = true;
    hideNew = true;
    hideConfirm = true;
    @Input() email = '';
    showValidationMessages = false;
    hideTimeout: any;
    /** constructor  */
    constructor(
        public readonly global: GlobalService,
        private readonly formBuilder: FormBuilder,
        private readonly apiResponseService: ApiResponseService
    ) {}
    ngOnChanges(changes: SimpleChanges): void {
        if (changes['email'] && this.email) {
            // Set the email field if the @Input value changes
            this.form.patchValue({email: this.email});
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * handle user login flow.
     */
    resetPassword() {
        // check if the form is invalid.
        // mark all form fields as touched to trigger validation messages.
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }

        this.apiResponseService
            .resetRandomPassword(this.email, this.form.value.oldPassword, this.form.value.newPassword)
            .subscribe((response) => {
                if (response.message === 'updated') {
                    this.global.basicAlert('Success', 'Password updated successfully', true);
                    if (this.global.loggedInUser) {
                        this.global.pushPage('dashboard');
                    } else {
                        this.global.pushPage('login');
                    }
                }
            });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * initializes and returns a form group for user login
     *
     * @returns FormGroup instance for user login
     */
    private _initForm() {
        return this.formBuilder.group(
            {
                email: [{value: '', disabled: true}, Validators.required],
                oldPassword: ['', Validators.required],
                newPassword: [
                    '',
                    [
                        Validators.required,
                        Validators.minLength(8), // Minimum length of 8 characters
                        Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*]).*$/),
                    ], // Sync validators
                ],
                confirmPassword: ['', Validators.required],
            },
            {validators: mustMatch('newPassword', 'confirmPassword')}
        );
    }
    checkPasswordValidation(target) {
        const password = target?.value;
        this.global.isPasswordValid.minLength = password?.length >= 8;
        this.global.isPasswordValid.lowerCase = /[a-z]/.test(password);
        this.global.isPasswordValid.upperCase = /[A-Z]/.test(password);
        // eslint-disable-next-line id-blacklist
        this.global.isPasswordValid.number = /\d/.test(password);
        this.global.isPasswordValid.symbol = this.global.allowedSymbols.test(password);

        const allValid =
            this.global.isPasswordValid.minLength &&
            this.global.isPasswordValid.lowerCase &&
            this.global.isPasswordValid.upperCase &&
            this.global.isPasswordValid.number &&
            this.global.isPasswordValid.symbol;
        if (allValid) {
            clearTimeout(this.hideTimeout);
            this.hideTimeout = setTimeout(() => {
                this.showValidationMessages = false;
            }, 1000);
        } else {
            clearTimeout(this.hideTimeout);
            this.showValidationMessages = true;
        }
    }
}
