import {NgModule} from '@angular/core';
import {NgxMaskModule} from 'ngx-mask';
import {IonicModule} from '@ionic/angular';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {Route, RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {MatInputModule} from '@angular/material/input';
import {DirectivesModule} from 'src/app/utils/directives';
import {MatFormFieldModule} from '@angular/material/form-field';

import {ProfilePage} from './profile.page';
import {DetailsFormComponent} from './details-form';
import {ResetPasswordComponent} from './reset-password';
import {ComponentsModule} from 'src/app/components/components.module';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {CommonModule} from '@angular/common';
import {MfaStatusComponent} from './mfa-status/mfa-status.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MfaEnableModal} from './mfa-enable/mfa-enable.modal';
import {VerifyPhnModal} from './verify-phn/verify-phn.modal';
const routes: Route[] = [{path: '', component: ProfilePage}];

@NgModule({
    declarations: [
        ProfilePage,
        DetailsFormComponent,
        ResetPasswordComponent,
        MfaStatusComponent,
        MfaEnableModal,
        VerifyPhnModal,
    ],
    imports: [
        CommonModule,
        IonicModule,
        RouterModule.forChild(routes),
        ComponentsModule,
        TranslateModule,
        ReactiveFormsModule,
        NgxMaskModule,
        MatFormFieldModule,
        MatInputModule,
        DirectivesModule,
        ScrollingModule,
        MatSlideToggleModule,
        FormsModule,
    ],
})
export class ProfileModule {}
