import {Component, OnInit} from '@angular/core';
import {ApiResponseService} from 'src/app/services/api-response.service';
import {GlobalService} from 'src/app/services/global.service';
import {MfaEnableModal} from '../mfa-enable/mfa-enable.modal';

@Component({
    selector: 'app-mfa-status',
    templateUrl: './mfa-status.component.html',
    styleUrls: ['./mfa-status.component.scss'],
})
export class MfaStatusComponent implements OnInit {
    mfaEnabled = false; // Default value to avoid undefined

    constructor(private apiResponseService: ApiResponseService, private global: GlobalService) {}

    ngOnInit(): void {
        this.checkMfaStatus();
    }

    checkMfaStatus(): void {
        this.apiResponseService.getMfaStatus().subscribe((response) => {
            this.mfaEnabled = response?.data[0]?.isEnabled || false; // Ensure boolean
        });
    }

    toggleMfaStatus(event: any): void {
        const newValue = event.checked; // Get the current toggle state
        this.global.showLoading();
        this.mfaEnabled = newValue; // Sync the variable with the toggle state

        this.apiResponseService.postMfaStatus(this.mfaEnabled).subscribe((response) => {
            if (response.message === 'success' && this.mfaEnabled) {
                this.global.hideLoading();
                this.apiResponseService.validateMFA().subscribe((resp) => {
                    if (resp?.data?.isMfaRequired === true) {
                        this.enableMFA();
                    }
                });
            }

            // Refresh status after update
        });
    }

    async enableMFA() {
        const modal: HTMLIonModalElement = await this.global.modalCtrl.create({
            component: MfaEnableModal,
            cssClass: 'auto-height ion-disable-focus-trap',
        });
        modal.onDidDismiss().then((detail) => {
            this.checkMfaStatus();
        });
        await modal.present();
    }
}
