import {Router} from '@angular/router';
import {Component, Input} from '@angular/core';

import {NavItem} from '../sidebar.model';
import {GlobalService} from 'src/app/services/global.service';

@Component({
    selector: 'app-nav-item',
    templateUrl: './nav-item.component.html',
    styleUrls: ['../sidebar.component.scss'],
    styles: [
        `
            :host {
                width: unset !important;
            }
        `,
    ],
})
export class NavItemComponent {
    /** nav bar page */
    @Input()
    page: NavItem;

    /** wether to display icon or not */
    @Input()
    displayIcon = true;

    /** any extra set of classes to use */
    @Input()
    paneClass?: string;

    /** constructor */
    constructor(private readonly router: Router, private readonly global: GlobalService) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * navigate to the page selected by user
     *
     * @param page user selected page
     */
    routeToPage(page: NavItem) {
        // if the URL exist in page then navigate to the URL
        if (page.url) {
            this.global.navCtrl.navigateRoot(page['url']);
            return;
        }

        // perform actions based on the selected page title
        switch (page.title) {
            case 'Logout': {
                this.global.logoutUser();
                break;
            }
            case 'Help': {
                this.global.launchExternal('https://help.tenantpay.com/support/home');
                break;
            }
        }
    }

    /**
     * check if the current URL matched the provided page URL
     *
     * @param page selected page
     */
    getActiveRouteClass(page: NavItem): boolean {
        if (page.title === 'Yardi') {
            return this.router.url.includes('yardi');
        }
        if (page.title === 'KYC') {
            return this.router.url.includes('kyc-portal');
        }
        if (page.title.includes('Quickbooks')) {
            return this.router.url.includes('quickbooks');
        }
        if (page.title === 'Integrations') {
            return this.router.url.includes('integrations');
        }
        return this.router.url === `/${page.url}`;
    }
}
