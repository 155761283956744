<div>
    <div class="form__heading-container" style="text-align: center">
        <ion-text class="form__heading mb-3">Complete Multi-Factor Authentication</ion-text>
        <ion-text style="text-transform: none">
            We’ve sent a verification code to your phone. Please check your messages to ensure you receive it. Enter the
            code below to complete the verification process.
        </ion-text>
    </div>

    <!-- Using flexbox to center the OTP input -->
    <div class="form__form-field mb-3 mt-3" style="display: flex; justify-content: center; align-items: center">
        <ng-otp-input
            [config]="{length: length, allowNumbersOnly: true}"
            (onInputChange)="onOtpChange($event)"></ng-otp-input>
    </div>

    <ion-button
        class="btn__primary form__btn mt-3"
        tappable
        id="loginBtn"
        expand="block"
        [disabled]="disableSubmit"
        (click)="onSubmit()"
        >{{ 'label.verify' | translate }}</ion-button
    >
</div>
