/** auth related route page options */
export enum AuthPageOptions {
    LOGIN = 'login',
    SIGN_UP = 'sign-up',
    FORGOT_PASSWORD = 'forgot-password',
    VERIFY_OTP = 'verify-otp',

    MFA = 'mfa',

    RESET_RANDOM_PASSWORD = 'password-reset',

}
