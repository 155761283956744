import {tap} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {API_URL} from 'src/app/pathConfig';
import {GlobalService} from 'src/app/services/global.service';
import {ProfileDetails, SecondaryContact} from './profile.modal';

@Injectable({providedIn: 'root'})
export class ProfileService {
    /** subject containing latest value of the profile details */
    profileDetails$ = new BehaviorSubject<ProfileDetails>(undefined);

    /** base URL which will be same in every API call */
    private readonly baseUrl = `${API_URL}/user`;

    /** constructor */
    constructor(private readonly http: HttpClient, private readonly global: GlobalService) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * update the logged in manager profile details
     *
     * @param profileDetails the new details which needs to be updated
     */
    updateProfileDetails(profileDetails: object, getProfileDetails = true) {
        return this.http
            .put(`${this.baseUrl}/updateManagerProfile`, profileDetails, {withCredentials: true})
            .subscribe(() => {
                if (getProfileDetails) {
                    this.getProfileDetails().subscribe((response) => {
                        if (response) {
                            this.global.basicAlert('', 'label.profile_updated_successfully', true);
                        }
                    });
                }
            });
    }

    /**
     * get the logged in manager profile details
     */
    getProfileDetails() {
        return this.http.get<ProfileDetails>(`${this.baseUrl}/getManagerProfile`, {withCredentials: true}).pipe(
            tap((profileDetails) => {
                this.profileDetails$.next(profileDetails);
            })
        );
    }

    /**
     * reset the password of logged in user
     */
    resetPassword(passwordData: object) {
        this.global.showLoading();
        this.global.sendAjaxRequest('login/resetPassword', passwordData).subscribe((response) => {
            let msg = '';
            if (response.message === 'success') {
                msg = 'label.password_updated';
            } else if (response.message === 'invalid_password') {
                msg = 'label.invalid_old_pass';
            }
            this.global.basicAlert('', msg, true);
            this.global.hideLoading();
        });
    }

    /**
     * get secondary contact data from BE
     *
     * @param id secondary contact id
     */
    getSecondaryContact(id: number) {
        return this.http.get<SecondaryContact>(`${this.baseUrl}/secondaryContact/${id}`, {withCredentials: true});
    }

    /**
     * update the secondary contact details
     *
     * @param emailOrUsername email or username as unique identifier
     * @param isActive status of the contact
     */
    updateSecondaryContact(emailOrUsername: string, isActive: boolean) {
        return this.http
            .put(`${this.baseUrl}/secondaryContact`, {emailOrUsername, isActive}, {withCredentials: true})
            .subscribe();
    }
}
