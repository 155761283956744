<!-- sign in form -->
<form [formGroup]="form" class="form" (keyup.enter)="login()">
    <!-- manage login heading -->
    <div class="form__heading-container">
        <ion-text class="form__heading">
            {{ 'label.manager_login' | translate }}
        </ion-text>
    </div>

    <!-- email input field -->
    <!-- <ion-item *ngIf="form.controls.email as emailCtrl" class="w-full form__input">
        <ion-label position="floating">{{ 'label.email' | translate }} / {{ 'label.user_name' | translate }}</ion-label>
        <ion-input appEnterClick type="email" formControlName="email"> </ion-input>
        <ion-note *ngIf="emailCtrl.touched && emailCtrl.hasError('required')" slot="error">
            {{ 'errors.required' | translate }}
        </ion-note>
    </ion-item>-->

    <div class="w-full w-100 form__form-field mb-3">
        <mat-label class="form__input-label"
            >{{ 'label.email' | translate }} / {{ 'label.user_name' | translate }}</mat-label
        >
        <mat-form-field>
            <input
                matInput
                formControlName="email"
                type="text"
                required
                appNoLeadingTrailingSpaces
                data-cy="app-login-form-email" />
            <mat-error appFieldErrors></mat-error>
        </mat-form-field>
    </div>

    <!-- password input field -->
    <!--<ion-item *ngIf="form.controls.password as passwordCtrl" class="w-full form__input !mb-2.5">
        <ion-label position="floating">{{ 'label.password' | translate }}</ion-label>
        <ion-input appEnterClick type="password" formControlName="password"> </ion-input>
        <ion-note *ngIf="passwordCtrl.touched && passwordCtrl.hasError('required')" slot="error">
            {{ 'errors.required' | translate }}
        </ion-note>
    </ion-item>-->
    <div class="w-full w-100 form__form-field">
        <mat-label class="form__input-label">{{ 'label.password' | translate }} </mat-label>
        <mat-form-field>
            <input matInput formControlName="password" [type]="hide ? 'password' : 'text'" required />
            <ion-icon matSuffix (click)="hide = !hide" name="{{ hide ? 'eye-off' : 'eye' }}"></ion-icon>
            <mat-error appFieldErrors></mat-error>
        </mat-form-field>
    </div>
    <div class="form__captcha mb-5">
        <re-captcha id="recaptcha" formControlName="recaptchaToken" (resolved)="onCaptchaResolved($event)">
        </re-captcha>
        <mat-error *ngIf="form.get('recaptcha')?.hasError('required')">
            {{ 'errors.recaptcha_required' | translate }}
        </mat-error>
    </div>

    <!-- forgot password request initiator button -->
    <span class="form__btn-forgot mb-6 mt-0 ml-auto" (click)="global.pushPage('forgot-password')">{{
        'label.forgot_ur_password' | translate
    }}</span>

    <!-- login button -->
    <ion-button
        type="submit"
        class="w-full btn__primary form__btn w-100"
        tappable
        id="loginBtn"
        expand="block"
        (click)="login()"
        [disabled]="form.invalid">
        {{ 'label.login' | translate }}</ion-button
    >

    <!--
        old implementation of keep me logged in checkbox
        translations and key `keepmeLoggedIn` does not exist in the code
        keeping it here for backwards compatibility.
        might be removed in future.
     -->
    <ion-item lines="none" *ngIf="global.isMobileApplication">
        <ion-checkbox slot="start" [(ngModel)]="global['keepmeLoggedIn']"></ion-checkbox>
        <ion-label>{{ 'label.remember_me' | translate }}</ion-label>
    </ion-item>
    <!-- end of checkbox code -->

    <!--
        sign up page button
        temporarily hidden using ngIf intentionally
     -->
    <div *ngIf="false" class="flex items-center gap-1.5">
        <span class="form__btn-reset !cursor-default !text-[#99A1B7]">{{ 'label.not_a_member_yet' | translate }}</span>
        <span class="form__btn-reset" (click)="global.pushPage('sign-up')">{{ 'label.sign_up' | translate }}</span>
    </div>
</form>
