import {Component} from '@angular/core';
import {AbstractControl, FormBuilder, Validators} from '@angular/forms';
import {ProfileService} from '../profile.service';
import {getAddressFormGroup} from '../../../utils/helpers';
import {AddressFormGroup} from 'src/app/components/location-search-v2';
import parsePhoneNumber from 'libphonenumber-js';
import {GlobalService} from 'src/app/services/global.service';
import {VerifyPhnModal} from '../verify-phn/verify-phn.modal';
import {MfaEnableModal} from '../mfa-enable/mfa-enable.modal';
import {ApiResponseService} from 'src/app/services/api-response.service';

@Component({
    selector: 'app-profile-details-form',
    templateUrl: './details-form.component.html',
})
export class DetailsFormComponent {
    /** Profile details form group */
    form = this.initForm();
    mfaEnabled = 'false';
    private originalFormValue: any;
    /** Original phone details */
    private originalPhoneNumber: string | null = null;

    /** Profile image URL */
    profileImg: string | null;

    /** Constructor */
    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly profileService: ProfileService,
        private global: GlobalService,
        private apiResponseService: ApiResponseService
    ) {
        this.patchProfileData();
    }

    /**
     * Updates the profile details at the backend
     */
    async updateProfileDetails() {
        const currentFormValue = this.form.getRawValue();

        // Check if form values are identical to original values
        if (JSON.stringify(this.originalFormValue) === JSON.stringify(currentFormValue)) {
            this.global.basicAlert('No Changes', 'No changes detected to save.');
            return;
        }
        const addressGroup = this.form.get('address')?.value;
        if (!addressGroup.fullAddress?.trim()) {
            const concatenatedAddress = [
                addressGroup.streetName,
                addressGroup.city,
                addressGroup.province,
                addressGroup.postalCode,
                addressGroup.country,
            ]
                .filter(Boolean) // Remove empty or null values
                .join(', ');
            this.form.get('address')?.get('fullAddress')?.setValue(concatenatedAddress);
            this.form.get('address')?.get('countryCode')?.setValue('CA');
        }
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            this.form.controls.address.updateValueAndValidity();
            return;
        }

        // Check if phone number or extension has changed
        const currentPhoneNumber = this.form.get('phoneNumber')?.value;

        if (currentPhoneNumber !== this.originalPhoneNumber) {
            // Ensure the '+' is preserved
            const completePhnNumber = `+1${currentPhoneNumber}`;
            this.openVerifyPhnModal(completePhnNumber);
            return;
        }

        // Prepare the updated form value
        const updatedFormValue = {
            ...this.form.value,
            linkedinUrl: this.form.value.linkedinUrl === '' ? null : this.form.value.linkedinUrl,
        };
        delete updatedFormValue.phoneNumber;

        // Call the service to update profile details
        this.profileService.updateProfileDetails(updatedFormValue);
    }
    async openVerifyPhnModal(currentPhoneNumber) {
        const modal: HTMLIonModalElement = await this.global.modalCtrl.create({
            component: VerifyPhnModal,
            componentProps: {
                phoneNumber: currentPhoneNumber,
            },
            cssClass: 'auto-height ion-disable-focus-trap',
        });
        modal.onDidDismiss().then((detail) => {
            if (detail.data?.verified) {
                const updatedFormValue = {
                    ...this.form.value,
                    linkedinUrl: this.form.value.linkedinUrl === '' ? null : this.form.value.linkedinUrl,
                };

                // Call the service to update profile details
                this.profileService.updateProfileDetails(updatedFormValue, false);
                this.checkMfaStatus();
            } else {
                this.global.basicAlert('Error', 'Phone number verification failed. Profile not saved.');
            }
        });
        await modal.present();
    }
    /**
     * Verifies the phone number by sending a verification code
     */
    async enableMFA() {
        const modal: HTMLIonModalElement = await this.global.modalCtrl.create({
            component: MfaEnableModal,
            cssClass: 'auto-height ion-disable-focus-trap',
        });
        modal.onDidDismiss().then((detail) => {});
        await modal.present();
    }
    checkMfaStatus(): void {
        this.apiResponseService.getMfaStatus().subscribe((response) => {
            this.mfaEnabled = response?.data[0]?.isEnabled; // Ensure boolean

            if (this.mfaEnabled === 'true') {
                this.enableMFA();
            }
        });
    }
    /**
     * Initializes the profile details form
     */
    private initForm() {
        const linkedinUrlPatternValidator = (control: AbstractControl): {[key: string]: any} | null => {
            const value = control.value;
            if (value && !/^(https:\/\/)?(www\.)?linkedin\.com\/.*$/.test(value)) {
                return {linkedinUrlPattern: true};
            }
            return null;
        };

        const phoneNumberValidator = (control: AbstractControl): {[key: string]: any} | null => {
            const value = control.value;

            const completePhoneNumber = `+1${value}`.replace(/\D/g, '');
            const phoneNumber = parsePhoneNumber(completePhoneNumber, 'CA');

            if (value && !phoneNumber?.isValid()) {
                return {invalidPhoneNumber: true};
            }
            return null;
        };

        return this.formBuilder.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            profilePicturePath: this.formBuilder.control<string | null>(null),
            email: [{value: '', disabled: true}, [Validators.required, Validators.email]],
            notificationEmail: [
                '',
                [
                    Validators.email,
                    Validators.required,
                    Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/),
                ],
            ],
            phoneNumberExtension: [''],
            phoneNumber: ['', [Validators.required, phoneNumberValidator]],
            title: [null],
            linkedinUrl: [null, linkedinUrlPatternValidator],
            address: this.formBuilder.group<AddressFormGroup>({
                ...getAddressFormGroup().controls,
                countryCode: this.formBuilder.control(''),
            }),
        });
    }

    /**
     * Patches the latest profile details data
     */
    private patchProfileData() {
        this.profileService.profileDetails$.subscribe((profileData) => {
            this.profileImg = profileData?.profilePictureUrl;
            const phoneNumberWithoutSymbols = profileData?.phoneNumber?.replace(/\D/g, '');
            const phoneNumber = phoneNumberWithoutSymbols?.slice(0, 10) || '';
            const linkedinUrl = profileData?.linkedinUrl || null;
            this.form.patchValue({...profileData, phoneNumber, linkedinUrl: linkedinUrl as null});
            setTimeout(() => {
                this.form.updateValueAndValidity(); // Ensures validators re-run
            });
            // Store original phone details
            this.originalPhoneNumber = phoneNumber;

            this.originalFormValue = this.form.getRawValue();
        });
    }
}
