import {NgModule} from '@angular/core';
import {IonicModule} from '@ionic/angular';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {RouterModule, Routes} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {AuthPage} from './auth.page';
import {AuthResolver} from './auth.resolver';
import {PortalInfoComponent} from './portal-info';
import {PipesModule} from 'src/app/pipes/pipe.module';
import {DirectivesModule} from 'src/app/utils/directives';
import {ComponentsModule} from '../../components/components.module';
import {ForgotPasswordComponent, LoginFormComponent, SignUpComponent} from './forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {NgxMaskModule} from 'ngx-mask';
import {MatSelectModule} from '@angular/material/select';
import {VerifyOtpComponent} from './forms/verify-otp/verify-otp.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {PrivacyPoliciesModalComponent} from './forms/sign-up/privacy-policies/privacy-policies.modal';
import {TermsAndConditionModalComponent} from './forms/sign-up/terms-and-condition/terms-and-condition.modal';

import {MfaComponent} from './forms/mfa/mfa.component';
import {NgOtpInputModule} from 'ng-otp-input';
import {environment} from 'src/environments/environment';

import {ResetRandomPasswordComponent} from './forms/reset-random-password/reset-random-password.component';
import {RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings} from 'ng-recaptcha';

const routes: Routes = [
    {
        path: '',
        component: AuthPage,
        resolve: [AuthResolver],
    },
];

/**
 * @todo rename the whole module to auth module and shift auth related modules in single place.
 */
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule,
        IonicModule,
        ReactiveFormsModule,
        PipesModule,
        ComponentsModule,
        DirectivesModule,
        RouterModule.forChild(routes),
        MatFormFieldModule,
        MatInputModule,
        NgxMaskModule,
        MatSelectModule,
        MatCheckboxModule,
        NgOtpInputModule,
        RecaptchaModule, // Add this
        RecaptchaFormsModule,
    ],
    declarations: [
        AuthPage,
        LoginFormComponent,
        PortalInfoComponent,
        ForgotPasswordComponent,
        SignUpComponent,
        VerifyOtpComponent,
        PrivacyPoliciesModalComponent,
        TermsAndConditionModalComponent,

        MfaComponent,

        ResetRandomPasswordComponent,
    ],
    providers: [
        AuthResolver,
        {
            provide: RECAPTCHA_SETTINGS,
            useValue: {
                siteKey: environment.recaptcha.siteKey,
            } as RecaptchaSettings,
        },
    ],
})
export class AuthPageModule {}
