<!-- New password and confirm password -->
<ion-grid class="info-grid-v2">
    <ion-row class="flex justify-between">
        <!-- New password -->
        <ion-col size="12" size-sm="6">
            <ion-label>
                Multi-Factor Authentication (MFA) is a security feature that adds an extra layer of protection to your
                account. In addition to your password, MFA requires a second verification step, typically involving a
                code sent to your registered phone number. This ensures that only you can access your account, even if
                someone else knows your password.
            </ion-label>
        </ion-col>

        <!-- Confirm password -->
        <ion-col size="12" size-sm="6" class="toggle-container">
            <p>
                <mat-slide-toggle
                    [checked]="mfaEnabled"
                    (change)="toggleMfaStatus($event)"
                    class="custom-slide-toggle"></mat-slide-toggle>
            </p>
        </ion-col>
    </ion-row>
</ion-grid>
