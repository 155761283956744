import {Component, OnInit} from '@angular/core';
import {NavParams} from '@ionic/angular';
import {ApiResponseService} from 'src/app/services/api-response.service';
import {GlobalService} from 'src/app/services/global.service';

@Component({
    selector: 'app-verify-phn-modal',
    templateUrl: './verify-phn.modal.html',
})
export class VerifyPhnModal implements OnInit {
    length = 4;
    disableSubmit = true;
    otp;
    phoneNumber;
    constructor(private apiResponseService: ApiResponseService, public global: GlobalService, private navParams: NavParams) {
        this.phoneNumber = this.navParams.get('phoneNumber');
    }
    ngOnInit(): void {
        this.sendCodeToPhn();
    }
    onOtpChange(event) {
        const otp = event.target.value;
        this.otp = otp;

        // Check if the OTP is of the correct length and numeric
        if (otp.length === this.length && /^\d+$/.test(otp)) {
            this.disableSubmit = false;
        } else {
            this.disableSubmit = true;
        }
    }
    sendCodeToPhn() {
        this.apiResponseService.sendCodeToPhone(this.phoneNumber).subscribe((response) => {});
    }

    onSubmit() {
        this.apiResponseService.verifyPhnNumber(this.global.isMobileApplication, this.phoneNumber, this.otp).subscribe((response) => {
            if (response.message === 'verification_success') {
                this.global.basicAlert('Success', 'Verification successful');
                this.global.closeModal({verified: true});
            }
        });
    }
}
