import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

import {AuthPageOptions} from './auth.model';

@Component({
    selector: 'app-auth',
    templateUrl: './auth.page.html',
    styleUrls: ['./auth.page.scss'],
})
export class AuthPage {
    /** current loaded page, used for conditionally rendering auth form */
    currentPage = AuthPageOptions.LOGIN;

    /** expose AuthPageOptions enum to html  */
    AuthPageOptions = AuthPageOptions;
    email;

    /** constructor */
    constructor(private readonly route: ActivatedRoute) {
        this.attachRouteChangeListener();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * attach the route change listener to get latest
     * loaded auth data information
     */
    private attachRouteChangeListener() {
        this.route.data.subscribe(({page}: {page: AuthPageOptions}) => {
            this.currentPage = page;

            if (page === AuthPageOptions.RESET_RANDOM_PASSWORD) {
                this.route.queryParams.subscribe((params) => {
                    this.email = decodeURIComponent(params['email'] || '');
                });
            }
        });
    }
}
