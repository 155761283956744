<!-- header -->
<ion-header>
    <ion-toolbar>
        <ion-title class="ion-text-capitalize">
            <span>Enable MFA</span>
        </ion-title>
        <ion-button slot="end" fill="none" (click)="global.closeModal(false)">
            <ion-icon name="close"></ion-icon>
        </ion-button>
    </ion-toolbar>
</ion-header>
<div class="overflow-y-auto">
    <div class="modal-padding pt-0 grid grid-cols-2 gap-x-6 gap-y-1">
        <app-form-field label="Please enter the OTP sent to the phone number.">
            <mat-form-field>
                <input
                    matInput
                    required
                    type="text"
                    [(ngModel)]="otp"
                    name="otp"
                    maxlength="4"
                    inputmode="numeric"
                    pattern="\d*"
                    appNoWhitespace
                    (input)="onOtpChange($event)" />
                <mat-error appFieldErrors></mat-error>
            </mat-form-field>
        </app-form-field>
    </div>
</div>

<!-- action buttons container -->
<ion-footer>
    <ion-button class="btn__primary" (click)="onSubmit()" [disabled]="disableSubmit">Verify</ion-button>
</ion-footer>
