import {Component, OnInit} from '@angular/core';
import {ApiResponseService} from 'src/app/services/api-response.service';
import {GlobalService} from 'src/app/services/global.service';

@Component({
    selector: 'app-mfa-enable-modal',
    templateUrl: './mfa-enable.modal.html',
})
export class MfaEnableModal implements OnInit {
    length = 4;
    disableSubmit = true;
    otp;
    constructor(private apiResponseService: ApiResponseService, public global: GlobalService) {}
    ngOnInit(): void {
        this.initiatePhoneNumberMFA();
    }
    onOtpChange(event) {
        const otp = event.target.value;
        this.otp = otp;

        // Check if the OTP is of the correct length and numeric
        if (otp.length === this.length && /^\d+$/.test(otp)) {
            this.disableSubmit = false;
        } else {
            this.disableSubmit = true;
        }
    }
    initiatePhoneNumberMFA() {
        this.apiResponseService.initiatePhoneNumberMFA().subscribe((response) => {});
    }
    onSubmit() {
        this.apiResponseService.verifyMfaCode(this.otp, this.global.isMobileApplication).subscribe((response) => {
            if (response.message === 'verification_success') {
                this.global.basicAlert('Success', 'Verification successful');
                this.global.closeModal();
                this.global.setLocalStorage('tenantpayToken', response?.data?.loginToken);

                this.global.initLoggedInUser(true);
            }
        });
    }
}
