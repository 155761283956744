import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {SaveResidentModal} from './save-resident.modal';
import {ComponentsModule} from 'src/app/components/components.module';
import {PipesModule} from 'src/app/pipes/pipe.module';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {DirectivesModule} from 'src/app/utils/directives';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSelectModule} from '@angular/material/select';
import {NgxMaskModule} from 'ngx-mask';
import {MatRadioModule} from '@angular/material/radio';
import {UserCreatedModal} from './user-created/user-created.modal';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {MatDatepickerModule} from '@angular/material/datepicker';

@NgModule({
    declarations: [SaveResidentModal, UserCreatedModal],
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule,
        IonicModule,
        ComponentsModule,
        PipesModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        DirectivesModule,
        MatCheckboxModule,
        MatSelectModule,
        NgxMaskModule,
        MatRadioModule,
        MatDatepickerModule,
    ],
    providers: [
        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'YYYY-MM-DD', // this is how your date will be parsed from Input
                },
                display: {
                    dateInput: 'YYYY-MM-DD', // this is how your date will get displayed on the Input
                    monthYearLabel: 'MMMM YYYY',
                    dateA11yLabel: 'LL',
                    monthYearA11yLabel: 'MMMM YYYY',
                },
            },
        },
    ],
})
export class SaveResidentModalModule {}
