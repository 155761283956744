import {Component, Input} from '@angular/core';
import {ComboBoxComponent} from '../combo-box.component';

/**
 * a version 2 of the combo box implement using material select.
 * It is a work under progress and for now only support selection of single item.
 *
 * @TODO: implement the multiselect functionality
 */
@Component({
    selector: 'combo-box-v2',
    templateUrl: './v2.component.html',
    styleUrls: ['../combo-box.component.scss', './v2.component.scss'],
})
export class ComboBoxComponentV2 extends ComboBoxComponent {
    @Input() required: boolean | '' = false;
    @Input() label = '';
    @Input() isUnitField = false;
    @Input() showStatus = false;
}
